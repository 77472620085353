
  import HourglassLoader from "@/components/loader/HourglassLoader.vue";
  import { onBeforeMount, ref, defineComponent, inject, watchEffect, Ref } from "vue";
  import SecondaryModal from "@/components/modal/SecondaryModal.vue";
  import InputWrapper from "@/components/InputWrapper.vue";
  import { apiGet, apiPost } from "@/api";
  import { useRoute } from "vue-router";
  import { Toast } from "@/utils/notify";
  import Swal from "sweetalert2";
  
  export default defineComponent({
    name: "WithdrawalDetail",
    components: {
      HourglassLoader,
      SecondaryModal,
      InputWrapper,
    },
    setup() {
      const loading = ref(false);
      const data = ref(null);
      const userBalance = ref(null);
  
      const automatedResponses = ref([]);
      const customComment = ref(false);
      const declineComment = ref("");
  
      const RequestConfirmation = inject("RequestConfirmation");

      const approvalData: Ref<{
      amount: null | number;
      remark: string;
    }> = ref({
      amount: null,
      remark: "Approved Successfully",
    });
  
      const route = useRoute();
  
      const sureConfirmDecline = ref(false);
  
      watchEffect(() => {
        document.body.classList.toggle(
          "no-scroll-on-decline-modal",
          sureConfirmDecline.value
        );
      });
  
      const RefreshData = async () => {
        loading.value = true;
        try {
          const response = await apiGet(
            `/admin/deposits/transactions/${route.params.transactionId}`
          );
  
          const getAutomatedResponses = await apiGet(
            `/admin/misc/automated/response`
          );
  
          const getTotalBalance = await apiGet(`/wallets/${route.params.userId}`);
          userBalance.value = getTotalBalance.data.data.fiatWallets;
  
          automatedResponses.value =
            getAutomatedResponses.data.data.automatedResponse;
  
          let transaction = response.data.data.transaction;
          data.value = transaction;

          approvalData.value.amount = Number(
          response.data.data.transaction.amount
        );

  
          // const userBalanceResponse = await apiGet(
          //   `/wallets/${transaction.User.id}`
          // );
          // userBalance.value = userBalanceResponse.data.data.fiatWallets[0];
          Toast.fire({
            icon: "success",
            title: "Transaction Detail Fetched Successfully",
          }).then();
        } catch (e:any) {
          Toast.fire({
            icon: "warning",
            title: "Unable to fetch data: " + e.message,
          }).then();
        }
        loading.value = false;
      };
  
      // const getUserBalance = async () => {
      //   loading.value = true;
      //   try {
      //     const response = await apiGet(`/wallets/${data.value?.User.id}`);
      //     userBalance.value = response.data.data.fiatWallets[0];
      //     // Toast.fire({
      //     //   icon: "success",
      //     //   title: "User Detail Fetched Successfully",
      //     // }).then();
      //   } catch (e:any) {
      //     Toast.fire({
      //       icon: "warning",
      //       title: "Unable to fetch data: " + e.message,
      //     }).then();
      //   }
      //   loading.value = false;
      // };
  
      const ApproveTransaction = async () => {
        loading.value = true;
        try {
          await apiPost(
            `/admin/deposits/transactions/${route.params.transactionId}/approve`,
            {
              amount: approvalData.value.amount,
              imagesProof: ""
             }
          );
          Toast.fire({
            icon: "success",
            title: "Transaction Approved Successfully",
          }).then();
          await RefreshData();
        } catch (e:any) {
          Toast.fire({
            icon: "warning",
            title: "Unable to approve: " + e.message,
          }).then();
          loading.value = false;
        }
      };
  
      const ProcessTransaction = async () => {
        loading.value = true;
        try {
          await apiPost(
            `/admin/withdrawals/transactions/${route.params.transactionId}/retry`,
            { status: "PROCESSING" }
          );
          Toast.fire({
            icon: "info",
            title: "Transaction Processing",
          }).then();
          await RefreshData();
        } catch (e:any) {
          Toast.fire({
            icon: "warning",
            title: "Unable to approve: " + e.message,
          }).then();
          loading.value = false;
        }
      };
  
      const ManualTransaction = async () => {
        loading.value = true;
        try {
          await apiPost(
            `/admin/withdrawals/transactions/${route.params.transactionId}/manual`,
            { status: "MANUAL" }
          );
          Toast.fire({
            icon: "info",
            title: "Transaction now marked successful",
          }).then();
          await RefreshData();
        } catch (e:any) {
          Toast.fire({
            icon: "warning",
            title: "Unable to approve manually: " + e.message,
          }).then();
          loading.value = false;
        }
      };
  
      const DeclineTransaction = async (comment: string) => {
        // const { value: text } = await Swal.fire({
        //   input: "textarea",
        //   inputLabel: "Enter Reason/Comment for declining",
        //   inputPlaceholder: "Type your reason/comment here...",
        //   inputAttributes: {
        //     "aria-label": "Type your reason/comment here",
        //   },
        //   showCancelButton: true,
        // });
  
        // if (!text) {
        //   return;
        // }
  
        loading.value = true;
        try {
          await apiPost(
            `/admin/deposits/transactions/${route.params.transactionId}/decline`,
            { comments: comment }
          );
  
          sureConfirmDecline.value = false;
          Toast.fire({
            icon: "success",
            title: "Transaction Declined Successfully",
          }).then();
          await RefreshData();
        } catch (e:any) {
          Toast.fire({
            icon: "warning",
            title: "Unable to decline: " + e.message,
          }).then();
          loading.value = false;
        }
      };
  
      onBeforeMount(RefreshData);
  
      return {
        RefreshData,
        ApproveTransaction,
        DeclineTransaction,
        ProcessTransaction,
        ManualTransaction,
        userBalance,
        declineComment,
        sureConfirmDecline,
        automatedResponses,
        customComment,
        RequestConfirmation,
        route,
        data,
        loading,
        approvalData
      };
    },
  });
  